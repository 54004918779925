// ncsExternalLinks
// -------------------------------------------------- //

(function($) {

    $.fn.ncsExternalLinks = function() {

        var getRoot = function(address) {
            var pieces = address.split('.');
            var tld = pieces.pop();
            var rootDomain = pieces.pop();
            return rootDomain;
        };

        // Open external links in new window
        $('a, area')
            .filter(function() {
                return this.hostname && getRoot(this.hostname) !== getRoot(location.hostname);
            })
            .not('.iframe')
            .not('.ajax')
            .not('.local')
            .attr({
                target: "_blank"
            })
            .not(':has(img)')
            .not('.unstyled')
            .not('.no-icon')
            .addClass('external-link');

        return jQuery;
    }

    $.ncsExternalLinks = $.fn.ncsExternalLinks;

}(jQuery));

// ncsFileLinks
// -------------------------------------------------- //
(function($) {

    $.fn.ncsFileLinks = function() {

        // Open linked files in a new window
        $('a')
            .filter(function() {
                return (/(pdf$)|(docx?$)|(pptx?$)|(ppsx?$)|(xlsx?)/i)
                    .test($(this)
                        .attr('href'));
            })
            .attr('target', '_blank');

        // Assign a class to link
        $('a[href$=".pdf"]')
            .not('a:has(img)')
            .addClass('pdf-link');
        $('a[href$=".doc"], a[href$=".docx"]')
            .not('a:has(img)')
            .addClass('doc-link');
        $('a[href$=".xls"], a[href$=".xlsx"]')
            .not('a:has(img)')
            .addClass('xls-link');
        $('a[href$=".pps"], a[href$=".ppsx"], a[href$=".ppt"], a[href$=".pptx"]')
            .not('a:has(img)')
            .addClass('ppt-link');
        $('a[href^="javascript:printPage"]')
            .not('a:has(img)')
            .addClass('print-link');

        return jQuery;
    }

    $.ncsFileLinks = $.fn.ncsFileLinks;

}(jQuery));

// ncsTopLink
// -------------------------------------------------- //
(function($) {

    $.fn.ncsTopLink = function(options) {

        var settings = $.extend({
            speed: 300,
            icon: 'chevron-up'
        }, options);

        $(this)
            .append('<a id="top-link"><i class="fa fa-' + settings.icon + '"></i></a>');

        $('#top-link')
            .hide();

        $(window)
            .scroll(function() {
                if ($(this)
                    .scrollTop() !== 0) {
                    $('#top-link')
                        .fadeIn();
                } else {
                    $('#top-link')
                        .fadeOut();
                }
            });

        $('#top-link')
            .click(function() {
                $('body,html')
                    .animate({
                        scrollTop: 0
                    }, settings.speed);
            });

        return jQuery;
    }

    $.ncsTopLink = $.fn.ncsTopLink;

}(jQuery));

// ncsBigLink
// -------------------------------------------------- //
(function($) {

    $.fn.ncsBigLink = function(options) {
        var settings = $.extend({

        }, options);

        this.each(function() {
            $(this)
                .css('cursor', 'pointer');
            $(this)
                .click(function() {
                    window.location = $(this)
                        .find('a')
                        .attr('href');
                    return false;
                });
        });

        return jQuery;
    }

    $.ncsBigLink = $.fn.ncsBigLink;

}(jQuery));

// ncsForms
// -------------------------------------------------- //
(function($) {

    $.fn.ncsForms = function(options) {

        var settings = $.extend({
            asterisk: '*',
            requiredNotice: false,
            helpText: 'indicates required field.'
        }, options);

        // Append asterisks to required fields' labels
        this.find('[required]')
            .each(function() {
                $(this)
                    .attr('title', this.title + ' (Required)');

                if ($(this)
                    .is(':radio')) {
                    var $label = $(this)
                        .closest('.form-group')
                        .find('.control-label');
                    if (!$label.hasClass('has-asterisk')) {
                        $label.addClass('has-asterisk');
                        $label.append('<b>' + settings.asterisk + '</b>');
                    }
                } else {
                    $(this)
                        .closest('.form-group')
                        .find('label')
                        .append('<b>' + settings.asterisk + '</b>');
                }
            });

        // Errors
        $('.form-group')
            .has('.form-error')
            .addClass('has-error');

        // Add a legend to the form?
        this.each(function() {
            if ($(this)
                .has('[required]')
                .length && settings.requiredNotice) {
                $(this)
                    .prepend('<p class="form-required-notice"><b>' + settings.asterisk + '</b> ' + settings.helpText + '</p>');
            }
        });

        return jQuery;
    }

    $.ncsForms = $.fn.ncsForms;

}(jQuery));